import * as React from 'react'

import { AccountBalance, AddShoppingCart } from '@mui/icons-material'
import { Box, Button, Stack, Typography } from '@mui/material'
import { Link } from '@reach/router'

import {
  AppContainer,
  AssetBadge,
  ButtonContainer,
  ButtonsContainer,
  SupportMailLink,
} from '../components'

type KoyweTracingViewProps = {
  success: boolean
}

export const KoyweTracingView = ({ success }: KoyweTracingViewProps) => (
  <Box sx={{ maxWidth: 'sm', mx: 'auto' }}>
    <AppContainer sx={{ p: 3 }}>
      <Stack
        pb={1}
        margin='auto'
        height='100%'
        width='100%'
        maxWidth='420px'
        spacing={1}
      >
        <AssetBadge
          animated
          symbol='USDT'
          height={100}
        />
        <Typography
          variant='h6'
          component='span'
          textAlign='center'
        >
          {success ? 'Dólares digitales en camino' : 'Compra no realizada'}
        </Typography>
        {success ? (
          <Typography
            variant='subtitle1'
            component='p'
            textAlign='center'
          >
            Tu balance se actualizará apenas confirmemos el pago con nuestro proveedor.
          </Typography>
        ) : (
          <React.Fragment>
            <Typography textAlign='center'>
              Si tuviste problemas para realizar la compra,
              escríbenos a nuestro correo de soporte:
            </Typography>
            <SupportMailLink variant='text' />
          </React.Fragment>
        )}
        <ButtonsContainer sx={{ alignItems: 'flex-end', pt: 1 }}>
          <ButtonContainer xs={6}>
            <Button
              fullWidth
              component={Link}
              to='../../'
              startIcon={<AddShoppingCart />}
              variant='outlined'
              sx={{ textAlign: 'center' }}
            >
              Volver a comprar
            </Button>
          </ButtonContainer>
          <ButtonContainer xs={6}>
            <Button
              fullWidth
              component={Link}
              to='../../../portfolio'
              startIcon={<AccountBalance />}
              variant='contained'
              sx={{ textAlign: 'center' }}
            >
              Ir a Portafolio
            </Button>
          </ButtonContainer>
        </ButtonsContainer>
      </Stack>
    </AppContainer>
  </Box>
)
