import * as React from 'react'

import { useQuery } from '@apollo/client'
import { Box } from '@mui/material'

import {
  AppContainer,
  Loading,
  NotAvailable,
  WithdrawBankForm,
} from '../components'
import { RAMP_CONFIG_QUERY } from '../queries'

import type { RampConfigData, RampConfigVars } from '../queries'

const BankWithdrawalFormWrapper = () => {
  const { loading, data } = useQuery<RampConfigData, RampConfigVars>(RAMP_CONFIG_QUERY)

  if (loading) {
    return <Loading />
  } else if (!data?.rampConfig.isVerified) {
    return (
      <NotAvailable
        message='Debes verificar tu identidad para utilizar esta función.'
      />
    )
  } else if (!data?.rampConfig.canWithdraw) {
    return (
      <NotAvailable
        message='Por el momento no tenemos opciones de retiro bancario disponibles en tu país.'
      />
    )
  }

  return <WithdrawBankForm />
}

export const WithdrawBankView = () => (
  <Box sx={{ maxWidth: 'sm', mx: 'auto' }}>
    <AppContainer sx={{ p: 3 }}>
      <BankWithdrawalFormWrapper />
    </AppContainer>
  </Box>
)
