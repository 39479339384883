import * as React from 'react'

import { SeoHeaders } from 'shared/components'
import { PortfolioDetailsView } from 'shared/views'

import type { RouteComponentProps } from '@reach/router'

type PortfolioDetailsProps = RouteComponentProps & {
  symbol?: string
}

const PortfolioDetails = ({ symbol }: PortfolioDetailsProps) => (
  <React.Fragment>
    <SeoHeaders title='Portafolio' />
    <PortfolioDetailsView
      symbol={symbol}
      graphUnits={['USDT', 'BTC']}
    />
  </React.Fragment>
)

export default PortfolioDetails
