import * as React from 'react'

import {
  AccountBalance,
  CreditCard,
  Download,
  History as HistoryIcon,
  Mail,
  ShowChart,
  SwapCalls,
  Upload,
} from '@mui/icons-material'
import { Link, Redirect, Router, useLocation } from '@reach/router'

import { DrawerDivider, DrawerList, DrawerListItem } from 'shared/components'

import Deposit from '../components/views/deposit'
import History from '../components/views/history'
import KoyweTracing from '../components/views/koywe_tracing'
import Market from '../components/views/market'
import MarketDetails from '../components/views/market_details'
import NotFound from '../components/views/not_found'
import Portfolio from '../components/views/portfolio'
import PortfolioDetails from '../components/views/portfolio_details'
import Purchase from '../components/views/purchase'
import Security from '../components/views/security'
import Support from '../components/views/support'
import Swap from '../components/views/swap'
import Withdraw from '../components/views/withdraw'
import WithdrawBank from '../components/views/withdraw_bank'
import WithdrawBlockchain from '../components/views/withdraw_blockchain'
import Layout from '../layouts/app'

const App = () => {
  const pathname = useLocation().pathname.replace(/\/$/, '')
  const basepath = '/app'

  const isCurrentPath = (href: string): boolean => pathname.startsWith(basepath + '/' + href)

  const listItem = (text: string, href: string, icon: React.ReactNode, color?: string) => (
    <DrawerListItem
      text={text}
      href={href}
      icon={icon}
      color={color}
      selected={isCurrentPath(href)}
      component={Link}
    />
  )

  const drawerContent = (
    <React.Fragment>
      <DrawerList>
        {listItem('Portafolio', 'portfolio', <AccountBalance />)}
        {listItem('Precios', 'market', <ShowChart />)}
      </DrawerList>
      <DrawerDivider />
      <DrawerList>
        {listItem('Retirar', 'withdraw', <Upload />)}
        {listItem('Depositar', 'deposit', <Download />)}
        {listItem('Convertir', 'swap', <SwapCalls />)}
        {listItem('Comprar', 'purchase', <CreditCard />)}
        {listItem('Historial', 'history', <HistoryIcon />)}
      </DrawerList>
      <DrawerDivider />
      <DrawerList>
        {listItem('Soporte', 'support', <Mail />)}
      </DrawerList>
    </React.Fragment>
  )

  return (
    <Router basepath={basepath}>
      <Layout
        path='/'
        drawerContent={drawerContent}
      >
        <Redirect
          noThrow
          from='/'
          to='portfolio'
        />
        <Portfolio path='portfolio' />
        <PortfolioDetails path='portfolio/:symbol' />
        <Market path='market' />
        <MarketDetails path='market/:symbol' />
        <Withdraw path='withdraw' />
        <WithdrawBank path='withdraw/bank' />
        <WithdrawBlockchain path='withdraw/blockchain' />
        <Deposit path='deposit' />
        <Deposit path='deposit/:symbol' />
        <Swap path='swap' />
        <Purchase path='purchase' />
        <KoyweTracing path='purchase/tracing' />
        <KoyweTracing path='purchase/tracing/:uuid' />
        <History path='history' />
        <Support path='support' />
        <Security path='security' />
        <NotFound default />
      </Layout>
    </Router>
  )
}

export default App
